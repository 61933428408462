import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Arrays, BaseModelIdName } from '../../../model/base-model';
import { BaseModalPageDirective } from '../../../pages/base-modal-page';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: [ './select.component.scss' ],
})
export class ModalSelectComponent<M extends BaseModelIdName> extends BaseModalPageDirective<ModalSelectComponent<M>, M[]> {
  public static open<T extends BaseModelIdName>(matDialog: MatDialog, options: SelectOptions<T>): MatDialogRef<ModalSelectComponent<any>, T[]> {
    const config = this.createDialog<SelectOptions<T>>(options);
    return matDialog.open(ModalSelectComponent, config);
  }

  public readonly title: string;
  public readonly instructions: string | null;
  public readonly items: readonly M[];
  public selectedItems: M[];
  public readonly multiple: boolean;
  public readonly submitColor: 'primary' | 'warn';
  public readonly submitText: string;
  public readonly cancelText: string;

  public constructor(matDialogRef: MatDialogRef<ModalSelectComponent<M>, M[]>, @Inject(MAT_DIALOG_DATA) data: SelectOptions<M>) {
    super(matDialogRef);

    this.title = data.title;
    this.instructions = data.instructions ?? null;
    this.items = data.items;
    this.selectedItems = [ ...data.selectedItems ];
    this.multiple = data.multiple;
    this.submitColor = data.submitColor ?? 'primary';
    this.submitText = data.submitText ?? 'bkwa.common.select';
    this.cancelText = data.cancelText ?? 'bkwa.common.cancel';
  }

  public confirm(): void {
    this.close(this.selectedItems);
  }

  public isSelected(item: M): boolean {
    return this.selectedItems.includes(item);
  }

  public toggle(item: M): void {
    if (this.multiple) {
      Arrays.toggle(this.selectedItems, item);
    } else {
      if (this.selectedItems[0] === item) {
        this.selectedItems = [];
      } else {
        this.selectedItems = [ item ];
      }
    }
  }
}

export interface SelectOptions<M extends BaseModelIdName> {
  title: string;
  instructions?: string;
  multiple: boolean;
  items: readonly M[];
  selectedItems: readonly M[];
  submitColor?: 'primary' | 'warn';
  submitText?: string;
  cancelText?: string;
}
