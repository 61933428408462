<form>
  <div mat-dialog-title>
    <h3>{{ title | translate }}</h3>
    <button mat-icon-button type="button" (click)="cancel()" [matTooltip]="'bkwa.common.close' | translate">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    @if (instructions != null) {
      <p>{{ instructions | translate }}</p>
    }
    <mat-list [class.less-padding]="!multiple">
      @for (item of items; track item) {
        <mat-list-item class="hover">
          <mat-checkbox [checked]="isSelected(item)" (change)="toggle(item)" [class.rounded]="!multiple">
            {{ item }}
          </mat-checkbox>
        </mat-list-item>
      }
    </mat-list>
  </mat-dialog-content>
  <mat-dialog-actions class="slim-buttons">
    <button type="button" mat-flat-button color="accent" (click)="cancel()">{{ cancelText | translate }}</button>
    <button type="submit" mat-flat-button [color]="submitColor" (click)="confirm()" [disabled]="!multiple && selectedItems.length === 0">
      {{ submitText | translate }}
    </button>
  </mat-dialog-actions>
</form>
